import React from 'react';
import BCLogo from './logo/BCID_H_rgb_rev.png';


const LogoFull = () => {
	// @todo fix this so we have a scalable format image
  return <img width="200px" alt="BC Mark" src={BCLogo}/>;
};

export default LogoFull;
